<template>
  <n-layout content-style="padding: 16px" class="noselect">
    <br><br>
    <n-space vertical align="center">
      <div v-if="data.isLoading">
        <n-spin size="large" />
      </div>
    </n-space>
    <countDownModal
      :fn="() => router.push({ path: '/auth' })"
      :timer="10"
      title="Registracijos patvirtinimas baigtas! ⏳"
      body="Netrukus jus perkelsime į prisijungimo langą."
      :show="data.verificationSuccess"
    />
  </n-layout>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'
import { useMessage } from 'naive-ui'
import { useStore } from 'vuex'
import { handleServerResponse } from '@/shared/serverResponseHandler'
import countDownModal from '@/components/CountDownModal.vue'

export default defineComponent({
  components: {
    countDownModal
  },
  setup () {
    const data = ref({
      isLoading: true,
      verificationSuccess: false
    })
    const store = useStore()
    const router = useRouter()
    const message = useMessage()

    function verifyEmail() {
      console.log(router.currentRoute._value.query)
      store.dispatch('auth/verifyEmail', router.currentRoute._value.query)
      .then(resp => {
        const responseStatusMap = {
          200: function() {
            data.value.isLoading = false
            data.value.verificationSuccess = true
            message.success(resp.data.message, { duration: 6000 })
            // countDownTimer()
          },
          400: function() {
            message.warning(resp.data.message)
            data.value.isLoading = false
            data.value.verificationSuccess = false
          }
        }
        handleServerResponse(resp, responseStatusMap)
      })
    }
    onMounted(() => {
      verifyEmail()
    })
    return {
      router,
      data
    }
  }
})
</script>

<style>
</style>
